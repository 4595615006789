import React, { Fragment } from 'react'
import Header from '../../components/Header/Header'
import Copyright from '../../components/Copyright/Copyright'
import { withContext } from '../../utils/helper'
import { Container, Row, Col } from '../../components/Bootstrap/Bootstrap'
import dummy from '../../utils/dummy'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import {
  Flex,
  Image,
  Text,
  Absolute,
  Relative,
  PageWrapper,
  FlexCell,
  BoxShadow,
} from '../../assets/styles/components'
import ProductSearchList from './components/ProductSearchList'
import ProductSearchCategory from './components/ProductSearchCategory'
import ProductSearchBrand from './components/ProductSearchBrand'
import ajax from './ajax'
import Footer from '../../components/Footer/Footer'
import Pagination from './components/Pagination'
import { white } from '../../assets/styles/styles'
import { getParameterByName } from '../../utils/format'

class ProductSearch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      errordata: false,
      category: {
        mainCategory: {},
        secondCategories: [],
        secondCategory: {},
        thirdCategories: [],
        thirdCategory: {},
      },
      brand: [],
      product: [],
      filteredbrand: [],
      activeCategory: '',
      totalcount: 0,
      page: 1,
      totalpage: 1,
    }
    this.fetchData = this.fetchData.bind(this)
    this.fetchCategory = this.fetchCategory.bind(this)
    this.fetchBrand = this.fetchBrand.bind(this)
    this.add = this.add.bind(this)
  }
  async componentDidMount() {
    let root = getParameterByName('root')
    let parent = getParameterByName('parent')
    let id = this.props.location.pathname.split('-')
    id = id[id.length - 1]

    let mainCategory = await this.props.context.state.categories.find(
      g => g.id == root,
    )
    let secondCategories = mainCategory.child
    let secondCategory = await secondCategories.find(g => g.id == parent)
    let thirdCategories = secondCategory.child
    let thirdCategory = await thirdCategories.find(g => g.id == id)
    let category = {
      mainCategory,
      secondCategories,
      secondCategory,
      thirdCategories,
      thirdCategory,
    }

    this.setState({ category })
    window.scrollTo(0, 0)
    this.props.context.update('history', this.props.history)
    // setTimeout(() => this.props.context.update('showDandanLoader', false), 1000)
    await this.fetchBrand()
    await this.fetchData(1, thirdCategory)
    // this.fetchCategory()
  }
  async componentDidUpdate(prevProps, prevState) {
    if (
      prevState.category.thirdCategory !== this.state.category.thirdCategory
    ) {
      await this.fetchBrand()
      await this.fetchData(1)
    }
  }

  componentWillUnmount() {
    this.props.context.update('showDandanLoader', true)
  }
  async fetchData(
    page = 1,
    activeCategory = this.state.category.thirdCategory,
  ) {
    window.scrollTo(0, 0)
    const { token } = this.props.context.state
    this.setState({
      loading: true,
      errordata: false,
    })
    let brand = []
    this.state.brand.map(g => {
      if (g.checked) brand.push(g.id)
      return g
    })
    let res = await ajax.getProductList(
      page,
      activeCategory ? activeCategory.id : '',
      brand,
      token,
    )
    if (!res.success) {
      this.setState({ loading: false, errordata: true, loadingCategory: false })
      return false
    }
    let totalcount = res.data.product_count
    let totalpage = 1
    if (res.data.total_page) totalpage = res.data.total_page
    else {
      totalpage = parseInt(totalcount / 15)
      if (totalcount % 15 != 0) totalpage++
    }
    if (totalpage < 1) totalpage = 1

    let category = {
      ...this.state.category,
      thirdCategory: activeCategory,
    }
    this.setState({
      loading: false,
      loadingCategory: false,
      product: res.data.product,
      totalcount,
      totalpage,
      page,
      category,
    })
    setTimeout(() => this.props.context.update('showDandanLoader', false), 1000)
  }
  async fetchCategory() {
    const { token } = this.props.context.state
    this.setState({ loadingCategory: true, errordataCategory: false })
    let res = await ajax.getProductCategory(
      this.props.context.state.category.mainCategory.id,
      token,
    )
    if (!res.success) {
      this.setState({ loadingCategory: false, errordataCategory: true })
      return false
    }
    let category = []
    if (res.data.length > 0) {
      category = res.data[0].child.find(
        g => g.name === this.props.context.state.category.secondCategory.name,
      )
      if (category && category.child) category = category.child
      else category = []
    }
    this.setState({
      category,
      loadingCategory: false,
    })
  }
  async fetchBrand(keyword = '') {
    const { token } = this.props.context.state
    const activeCategory = this.state.category.thirdCategory
    this.setState({ loadingBrand: true, errordataBrand: false })
    let res = await ajax.getBrandOnCategory(
      keyword,
      activeCategory ? activeCategory.id : '',
      token,
    )
    if (!res.success) {
      this.setState({ loadingBrand: false, errordataBrand: true })
      return false
    }
    let brand = res.data
    // if (this.props.location.state.brand) {
    //   brand = brand.map(g => {
    //     if (g.id == this.props.location.state.brand) g.checked = true
    //     return g
    //   })
    // }
    this.setState({
      brand,
      filteredbrand: brand,
      loadingBrand: false,
    })
  }
  filter = value => {
    let filteredbrand = [...this.state.brand]
    filteredbrand = filteredbrand.filter(g =>
      g.name.toUpperCase().includes(value.toUpperCase()),
    )
    this.setState({ filteredbrand })
  }
  checkBrand = index => {
    let brand = [...this.state.brand]
    brand[index].checked = !brand[index].checked
    this.setState({ brand }, () => this.fetchData())
  }
  async add(id, data) {
    const { token } = this.props.context.state
  
    if (!token) {
      this.props.context.update('showLogin', true)
      return false
    }
    this.props.context.update('showLoading', true)

    let res = await ajax.addToCart(id, 1, token)

    if (!res.success) {
      this.props.context.update('showLoading', false)
      this.props.context.update('error', res.message)
      return false
    }
    this.props.context.update('showLoading', false)
    this.props.context.update('successCart', data)
    this.props.context.update('refetchCart', true)
  }
  changeState = (key, value) => {
    this.setState({ [key]: value })
  }
  render() {
    const {
      loading,
      errordata,
      category,
      brand,
      filteredbrand,
      product,
      loadingCategory,
      errordataCategory,
      loadingBrand,
      errordataBrand,
      activeCategory,
      totalcount,
      page,
      totalpage,
    } = this.state
    const { location, context } = this.props
    return (
      <Fragment>
        <Header match={this.props.match} location={this.props.location} />

        <PageWrapper promo={this.props.context.state.promo.content}>
          <FlexCell>
            <Relative padding="1em 2em" mobileStyle={{ padding: '15px 20px' }}>
              <Breadcrumb
                text={`Home > ${category.mainCategory &&
                  category.mainCategory.name} > ${category.secondCategory &&
                  category.secondCategory.name}`}
                margin="0"
              />
              <Container>
                <Row>
                  <Col md={2} padding="0">
                    <BoxShadow
                      padding="10px 12px"
                      borderRadius="10px"
                      margin="10px 0 0"
                    >
                      <Relative
                        padding="0 30px 0 0"
                        mobileStyle={{ padding: 0 }}
                        backgroundColor={white}
                        // style={{
                        //   boxShadow: '0 1px 20px 0 rgba(155, 155, 155, 0.2)',
                        // }}
                      >
                        <ProductSearchCategory
                          data={category}
                          loading={loadingCategory}
                          errordata={errordataCategory}
                          location={location}
                          activeCategory={activeCategory}
                          changeState={this.changeState}
                        />
                        <ProductSearchBrand
                          loading={loadingBrand}
                          errordata={errordataBrand}
                          data={filteredbrand}
                          filter={this.fetchBrand}
                          checkBrand={this.checkBrand}
                        />
                      </Relative>
                    </BoxShadow>
                  </Col>
                  <Col md={10} padding="0">
                    <Relative padding="0 0 0 45px" mobileStyle={{ padding: 0 }}>
                      <ProductSearchList
                        data={product}
                        loading={loading}
                        errordata={errordata}
                        fetchData={this.fetchData}
                        totalcount={totalcount}
                        add={this.add}
                        category={category}
                      />

                      {loading ? null : (
                        <Pagination
                          totalcount={totalcount}
                          totalpage={totalpage}
                          fetch={this.fetchData}
                          page={page}
                        />
                      )}
                    </Relative>
                  </Col>
                </Row>
              </Container>
            </Relative>
          </FlexCell>

          <FlexCell>
            <Footer location={this.props.location} />
            <Copyright />
          </FlexCell>
        </PageWrapper>
      </Fragment>
    )
  }
}

export default withContext(ProductSearch)
