import React, { Fragment } from 'react'
import Header from '../../components/Header/Header'
import Copyright from '../../components/Copyright/Copyright'
import { withContext } from '../../utils/helper'
import { Container, Row, Col } from '../../components/Bootstrap/Bootstrap'
import dummy from '../../utils/dummy'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import {
  Flex,
  Image,
  Text,
  Absolute,
  Relative,
  PageWrapper,
  FlexCell,
  BoxShadow,
} from '../../assets/styles/components'
import ProductSearchList from './components/ProductSearchList'
import ProductSearchCategory from './components/ProductSearchCategory'
import ProductSearchBrand from './components/ProductSearchBrand'
import ajax from './ajax'
import Footer from '../../components/Footer/Footer'
import Pagination from './components/Pagination'
import { white } from '../../assets/styles/styles'
import { getParameterByName } from '../../utils/format'

class ProductResult extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
    }
  }

  componentDidMount() {
    this.setState({ loading: false })
  }
  
  render() {
   
    const { location, context } = this.props
    return (
      <Fragment>
        <Header match={this.props.match} location={this.props.location} />

        <PageWrapper promo={this.props.context.state.promo.content}>
          <FlexCell>
            <Relative padding="1em 2em" mobileStyle={{ padding: '15px 20px' }}>
              <Breadcrumb
                text={`Home > Level 1 > Level 2 > Level 3`}
                margin="0"
              />
              <Container>
                <Row>
                  <Col md={2} padding="0">
                    <BoxShadow
                      padding="10px 12px"
                      borderRadius="10px"
                      margin="10px 0 0"
                    >
                      <Relative
                        padding="0 30px 0 0"
                        mobileStyle={{ padding: 0 }}
                        backgroundColor={white}
                        // style={{
                        //   boxShadow: '0 1px 20px 0 rgba(155, 155, 155, 0.2)',
                        // }}
                     >
                      </Relative>
                    </BoxShadow>
                  </Col>
                  <Col md={10} padding="0">
                    <Relative padding="0 0 0 45px" mobileStyle={{ padding: 0 }}>
                     
                    </Relative>
                  </Col>
                </Row>
              </Container>
            </Relative>
          </FlexCell>

          <FlexCell>
            <Footer location={this.props.location} />
            <Copyright />
          </FlexCell>
        </PageWrapper>
      </Fragment>
    )
  }
}

export default withContext(ProductResult)
